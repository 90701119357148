import { render, staticRenderFns } from "./v3-layout.vue?vue&type=template&id=8b56ec5a&"
import script from "./v3-layout.vue?vue&type=script&lang=js&"
export * from "./v3-layout.vue?vue&type=script&lang=js&"
import style0 from "./v3-layout.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/builds/pagsmile/front-end/pagsmile-web/components/Header.vue').default,Footer: require('/builds/pagsmile/front-end/pagsmile-web/components/Footer.vue').default})
