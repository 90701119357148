//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import Lenis from '@studio-freight/lenis'
import Header from '@/components/v3-components/header.vue'
import Footer from '@/components/v3-components/footer.vue'
import ContatUs from '@/components/ContactUs.vue'
import MultiContatUs from '@/components/common/MultiContactUs.vue'
import SupportBtn from '@/components/common/SupportBtn.vue'
import JobsBubble from '@/components/common/JobsBubble.vue'
import { zenDeskFunc } from '@/plugins/utils'

export default {
  components: {
    Header,
    Footer,
    ContatUs,
    SupportBtn,
    MultiContatUs,
    JobsBubble
  },
  data () {
    return {
      isReady: false,
      geoInfo: null,
      opacity: 0,
      modalType: 0
    }
  },
  // async fetch () {
  //   const { locale } = this.$i18n
  //   // console.log({ locale })
  //   const isBlogPost = (this.$nuxt.$route.name || '').slice(9, 18) === 'blog-slug'
  //   if (locale && locale === 'en' && !isBlogPost) {
  //     try {
  //       const geoInfo = await fetch('https://api.pagsmile.com/index.php?r=api%2Fgetip')
  //         .then(res => res.json())
  //         .catch(function (e) {
  //           console.log(e)
  //         })
  //       const { country } = geoInfo
  //       // console.log('country', country)
  //       if (country) {
  //         this.geoInfo = { countryCode: country }
  //       }
  //     } catch (error) {
  //       console.log(error)
  //       this.opacity = 100
  //     }
  //   } else {
  //     this.opacity = 100
  //   }
  // },
  fetchOnServer: false,
  head () {
    const isBlogPost =
      (this.$nuxt.$route.name || '').slice(9, 18) === 'blog-slug'
    const headerAttr = this.$nuxtI18nHead({ addSeoAttributes: true })
    if (isBlogPost && headerAttr.link) {
      delete headerAttr.link
      delete headerAttr.meta
    }

    const { link } = headerAttr
    const isDev = process.env.ENVIRONMENT === 'DEV'
    const url = isDev ? 'https://web.pagsmile.com' : 'https://www.pagsmile.com'
    if (link && link.length) {
      link.forEach((itemLink, index) => {
        const { rel, hid, hreflang } = itemLink
        if (rel === 'alternate') {
          headerAttr.link[index].href = url + headerAttr.link[index].href
          if (hreflang && typeof hreflang === 'string') {
            headerAttr.link[index].hreflang = hreflang.toLowerCase()
          }
        } else if (rel === 'canonical' && hid === 'i18n-can') {
          headerAttr.link[index].href = url + headerAttr.link[index].href
        }
      })
    }
    if (headerAttr.meta) {
      headerAttr.meta.push({
        hid: 'description',
        name: 'description',
        content: this.$t('seo.description')
      })
    }
    return {
      ...headerAttr,
      script: [
        {
          hid: 'baidu-analytics',
          innerHTML: `
          window._agl = window._agl || [];
          (function () {
            _agl.push(
                ['production', '_f7L2XwGXjyszb4d1e2oxPybgD']
            );
            (function () {
                var agl = document.createElement('script');
                agl.type = 'text/javascript';
                agl.async = true;
                agl.src = 'https://fxgate.baidu.com/angelia/fcagl.js?production=_f7L2XwGXjyszb4d1e2oxPybgD';
                var s = document.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(agl, s);
            })();
          })();
          `,
          defer: true
        },
        {
          hid: 'ms-clarity-analytics',
          innerHTML: `(function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "crj28dqj2h");`,
          defer: true
        },
        {
          hid: 'amplitude-analytics',
          innerHTML: `
          (function () {
            var script1 = document.createElement('script');
            script1.type = 'text/javascript';
            script1.async = false;
            script1.src = 'https://cdn.amplitude.com/libs/analytics-browser-2.6.2-min.js.gz';
            var s1 = document.getElementsByTagName('script')[0];
            s1.parentNode.insertBefore(script1, s1);

            var script2 = document.createElement('script');
            script2.type = 'text/javascript';
            script2.async = false;
            script2.src = 'https://cdn.amplitude.com/libs/plugin-session-replay-browser-1.1.6-min.js.gz';
            var s2 = document.getElementsByTagName('script')[0];
            s2.parentNode.insertBefore(script2, s2);

            var script3 = document.createElement('script');
            script3.type = 'text/javascript';
            script3.async = false;
            script3.src = 'https://cdn.amplitude.com/libs/plugin-default-event-tracking-advanced-browser-0.8.0-min.js.gz';
            var s3 = document.getElementsByTagName('script')[0];
            s3.parentNode.insertBefore(script3, s3);

            script3.onload = () => {
              window.amplitude.init('6af28cb454fe72bcc6cce851cbd9ae77')
              window.amplitude.add(window.sessionReplay.plugin({sampleRate: 1}))
              window.amplitude.add(window.amplitudeDefaultEventTrackingAdvancedPlugin.plugin())
            };
          })();
          `,
          defer: true
        }
      ],
      __dangerouslyDisableSanitizers: ['script']
    }
  },
  computed: {
    showSupport () {
      return this.isReady && this.opacity === 100
    }
  },
  watch: {
    geoInfo (value) {
      const { countryCode } = value || {}
      this.isReady = false
      const queryString = window.location.search
      let pathname = window.location.pathname
        .replace('/zh/', '')
        .replace('/es/', '')
        .replace('/pt/', '')
        .replace('/zh', '')
        .replace('/es', '')
        .replace('/pt', '')
      if (pathname === '/') {
        pathname = ''
      }
      if (pathname.startsWith('/')) {
        pathname = pathname.slice(1)
      }
      if (countryCode.includes('zh')) {
        this.$i18n.locale = 'zh'
        if (window) {
          const currentPath = `/zh/${pathname || ''}${queryString}`
          this.$router.push(currentPath)
          this.$nextTick(() => {
            this.addScriptRaEmbed()
          })
        }
      } else if (countryCode.includes('br')) {
        this.$i18n.locale = 'pt'
        if (window) {
          const currentPath = `/pt/${pathname || ''}${queryString}`
          this.$router.push(currentPath)
          this.$nextTick(() => {
            this.addScriptRaEmbed()
          })
        }
      } else if (countryCode.includes('es')) {
        this.$i18n.locale = 'es'
        if (window) {
          const currentPath = `/es/${pathname || ''}${queryString}`
          this.$router.push(currentPath)
          this.$nextTick(() => {
            this.addScriptRaEmbed()
          })
        }
      } else {
        this.$i18n.locale = 'en'
        const currentPath = `/${pathname || ''}${queryString}`
        if (window) {
          this.$router.push(currentPath)
          this.$nextTick(() => {
            this.addScriptRaEmbed()
          })
        }
      }
      this.opacity = 100
      zenDeskFunc(this.$i18n.locale)
      setTimeout(() => {
        this.updateGeoInfo(value)
        this.isReady = true
      }, 500)
    }
  },
  mounted () {
    this.isReady = true
    // 根绝浏览器国家判断语言，巴西 中国 西班牙以外都是英语
    // const isBlogPost = (this.$nuxt.$route.name || '').slice(9, 18) === 'blog-slug'
    const curLocale = (navigator.language || navigator.browserLanguage).toLowerCase()
    if (window && window.location.port !== '8085' && curLocale === 'es-cl') {
      window.location.href = 'https://cl.pagsmile.com/'
    }
    this.geoInfo = { countryCode: curLocale }

    // const lenis = new Lenis({
    //   duration: 2
    // })
    // // lenis.on('scroll', (e) => {
    // //   console.log(e)
    // // })
    // function raf (time) {
    //   lenis.raf(time)
    //   requestAnimationFrame(raf)
    // }
    // requestAnimationFrame(raf)
  },
  methods: {
    checkGeoLocation (code) {
      if (code === 'en') {
        const { countryCode } = this.geoInfo || {}
        const { locale } = this.$i18n
        if (countryCode && countryCode === 'BR' && locale !== 'en') {
          this.$i18n.locale = 'en'
          if (this.sidebarActive) {
            this.sidebarActive = false
          }
        }
      }
    },
    updateGeoInfo (value) {
      if (value) {
        this.$nuxt.$emit('updateGeoInfo', value)
      }
    },
    setContactModalType (value) {
      // console.log('setContactModalType', value)
      if (value && typeof value === 'number') {
        // console.log('setContactModalType in')
        this.modalType = value
        this.$bvModal.show('multi-contact-us-modal')
      }
    },
    addScriptRaEmbed () {
      const raEmbed = document.createElement('script')
      raEmbed.type = 'text/javascript'
      raEmbed.async = true
      raEmbed.id = 'ra-embed-verified-seal'
      raEmbed.src = 'https://s3.amazonaws.com/raichu-beta/ra-verified/bundle.js'
      raEmbed.setAttribute('data-id', 'RzVrbG0wV3E2NmptM0RsbzpwYWdzbWlsZQ==')
      raEmbed.setAttribute('data-target', 'ra-verified-seal')
      raEmbed.setAttribute('data-model', '2')
      const s2 = document.getElementById('ra-verified-seal')
      s2.appendChild(raEmbed)
    }
  }
}
